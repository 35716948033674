.Dropdown {
  width: 100%;
  position: fixed;
  z-index: 2;
  background: rgb(20,20,20);
}

.line {
  height: 5px;
  background: rgb(137,169,197);
}

.logo {
  position: absolute;
  left: 2%;
  top: 15px;
  width: 120px;
  height: 40px;
  perspective: 120px;
}

.prism {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: translateZ(-20px);
  animation: logo 4s linear infinite;
}

.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: 'Julius Sans One', sans-serif;
  font-size: 20px;
  background: white;
  color: black;
  box-sizing: border-box;
  border: 3px solid rgb(137,169,197);
}

.front-face {
  transform: translateZ(20px);
}
.top-face {
  transform: rotateX(90deg) translateZ(20px);
}
.back-face {
  transform: rotateY(180deg) rotateZ(180deg) translateZ(20px);
}
.bottom-face {
  transform: rotateX(-90deg) translateZ(20px);
}
  
.title {
  padding: 0 60px;
  text-decoration: none;
  text-align: center;
  background: rgb(137,169,197);
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 40px;
  color: white;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
  width: fit-content;
}
  
.menu {
  font-size: 30px;
  color: white;
  right: 0;
  line-height: 60px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
  
#menucheck {
  display: none;
}

nav {
  display: flex;
  justify-content: center;
  height: 60px;
  font-family: 'Julius Sans One', sans-serif;
}
  
nav ul {
  padding: 0;
  margin: 0;
}

nav ul li, nav ul li a {
  background: rgb(20,20,20);
  position: relative;
  list-style: none;
  display: inline-block;
  padding: 0 15px;
  color: white;
  text-decoration: none;
  line-height: 60px;
  font-size: 14px;
  transition-duration: 0.5s;
}

nav ul li i {
  margin-left: 4px;
}
  
nav ul .selector, nav ul .selector a{
  padding: 0;
}

nav ul li:hover > a, nav ul li:hover {
  background: rgb(80,80,80);
}

nav ul ul {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: -1;
}

nav ul li ul li {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  transition: all 0.25s linear;
}

nav ul ul li a {
  width: 100%;
}

nav ul li:hover > ul li:nth-child(1) {
  z-index: 3;
  top: 60px;
}
nav ul li:hover > ul li:nth-child(2) {
  z-index: 2;
  top: 120px;
  transition-delay: 0.125s;
}
nav ul li:hover > ul li:nth-child(3) {
  z-index: 1;
  top: 180px;
  transition-delay: 0.25s;
}

@media (max-width: 1050px) {
  .menu {
    display: block;
    position: absolute;
    right: 0;
  }

  .left-nav, .right-nav {
    position: fixed;
    width: 100%;
    text-align: center;
    top: 65px;
    overflow: hidden;
  }

  .left-nav {
    z-index: 1;
  }

  .right-nav {
    height: 420px;
  }

  nav ul .menuItem {
    transition: all 0.5s;
  }

  nav ul .menuItem:nth-child(1){
    top: -60px;
  }
  nav ul .menuItem:nth-child(2) {
    top: -120px;
  }

  #menucheck:checked ~ .left-nav .menuItem:nth-child(1) {
    top: 0;
  }
  #menucheck:checked ~ .left-nav .menuItem:nth-child(2) {
    top: 0;
  }

  #menucheck:checked ~ .right-nav .menuItem:nth-child(1) {
    top: 120px;
  }
  #menucheck:checked ~ .right-nav .menuItem:nth-child(2) {
    top: 120px;
  }

  nav ul li a, nav ul li {
    display: block;
    width: 100%;
    padding: 0;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .logo {
    width: 80px;
  }
  .quote {
    font-size: 12px;
  }
  .menu {
    margin-right: 20px;
  }
  .title {
    font-size: 30px;
    padding: 0 30px;
  }
}

@keyframes logo {
  0% { transform: translateZ(-20px);}
  16.67% { transform: translateZ(-20px);}
  25% { transform: translateZ(-20px) rotateX(-90deg); }
  41.67% { transform: translateZ(-20px) rotateX(-90deg); }
  50% { transform: translateZ(-20px) rotateX(-180deg); }
  66.67% { transform: translateZ(-20px) rotateX(-180deg); }
  75% { transform: translateZ(-20px) rotateX(-270deg); }
  91.67% { transform: translateZ(-20px) rotateX(-270deg); }
  100% { transform: translateZ(-20px) rotateX(-360deg); }
}