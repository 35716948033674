.page-title {
  margin: 0;
  padding-top: 65px;
  text-align: center;
}

.slideshow {
  margin: 0 auto;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  max-width: 1050px;
  width: 70vw;
}

/*Entire Slideshow*/
.slick-slider {
  height: fit-content;
}

.slide-image {
  width: 100%;
  height: 70vh;
  min-height: 300px;
  max-height: 600px;
  object-fit: none;
}

/*Individual slide*/
.slick-list {
  min-height: 300px;
  max-height: 600px;
  height: fit-content;
}

.slide-arrow {
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  top: 0;
  height: 100%;
  color: black;
  z-index: 1;
  padding: 0 25px;
  font-size: 50px;
  width: 50px;
}

.prev {
  left: -100px;
}

.next {
  left: 100%;
}

.slide-arrow:hover {
  transition-duration: 0.5s;
  opacity: 0.5;
  background: grey;
}

.slide-button {
  height: 7px;
  width: 20px;
  box-sizing: border-box;
  border: 1px solid black;
  background: rgba(0,0,0,0.15);
  margin-top: 10px;
}

.slick-active .slide-button{
  background: rgb(137,169,197);
}

.slide-button:hover {
  transition-duration: 0.25s;
  background: rgb(60,60,60);
}

.content-container {
  width: 100%;
  padding-top: 70px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.container {
  display: grid;
  /* grid-template-columns: 49% 2% 49%; */
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  max-width: 1250px;
  margin: 0 auto;
  height: fit-content;
}

.container video {
  width: 100%;
}

.image-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: fit-content;
}

.left {
  grid-area: 1/1/1/1;
}

.right {
  grid-area: 1/2/1/2;
}

.image-content .header {
  background: black;
  color: white;
  padding: 0.5em;
  margin: 15px 0;
  width: fit-content;
  height: fit-content;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 20px 100%, 0 calc(100% - 20px), 0 100%);
}

.image-content .text {
  font-size: 17px;
  padding: 0;
  margin: 0 3em;
}

.link {
  text-decoration: none;
  color: black;
  border: 2px solid rgb(137,169,197);
  font-family: "Montserrat", sans-serif;
  border-radius: 15px;
  height: fit-content;
  width: fit-content;
  margin: 15px auto;
  padding: 0.5em;
}

.link:hover {
  background: rgba(100,100,100, 0.5);
  transition-duration: 0.5s;
}

.arrow {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 100%;
  margin: 50px 0;
}

.slideshow .arrow {
  margin-top: 50px;
}

.fa-chevron-down {
  font-size: 40px;
}

#first {
  height: 70vh;
}

@media (max-width:1250px) {
  .slideshow {
    display: none;
  }
}

@media (max-width: 950px) {
    .page-title {
      font-size: 75px;
    }
  
    .container {
      display: block;
      text-align: center;
    }
  
    .container video, .image-content{
      width: 80vw;
      margin: 0 auto;
    }
  
    .image-content .text {
      margin: 0;
    }
}