.Projects-demo {
    width: 100%;
    max-width: 1050px;
    padding: 0 calc(50vw - 525px);
    margin-bottom: 25px;
}

.Projects-demo table {
    width: 100%;
    margin: 0 auto;
}

.Projects-demo table tr {
    display: flex;
}

.Projects-demo table td {
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 1%;
    border: black solid 0.75px;
}

.Projects-demo a {
    text-decoration: none;
    color: black;
}

.demo-tab {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.demo-tab img {
    width: 100%;
    margin: 0 auto;
    opacity: 1;
    transition: opactiy 1s;
}

.demo-tab-description {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(137,169,197,0.8);
    transition: opacity 0.5s;
}

.project-demo-title {
    margin: 0;
}

.demo-tab img:hover ~ .demo-tab-description, .demo-tab-description:hover {
    opacity: 1;
}

.demo-tab img:hover, .demo-tab-description:hover ~ img {
    opacity: 0.5;
    transform: scale(1.1);
}