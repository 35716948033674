body {
  margin: 0;
}

.Main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: white;
  width: 100%;
}

.opening {
  width: 100%;
  height: 100vh;
}

.portrait {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation-duration: 2s;
}

.opening-text {
  position: absolute;
  text-align: center;
  left: 55%;
  top: 50%;
  animation-duration: 1s;
}

.page-title {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: lighter;
  font-size: 100px;
}

.introduction {
  padding-top: 80px;
}

.introduction-link {
  display: inline-block;
  position: relative;
  color: rgb(137,169,197);
  text-decoration: none;
  font-weight: bold;
}

.introduction-link:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(137,169,197);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.introduction-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.header {
  margin-left: calc(50vw - 525px);
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 40px;
}

.text {
  text-align: justify;
  text-align-last: center;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  color: black;
  line-height: 160%;
  max-width: 1050px;
  padding: 0 calc(50vw - 525px);
}

.skills {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin-top: 0;
    padding: 0 calc(50vw - 525px);
    max-width: 1050px;
}

.skills li {
    width: 50%;
    margin: 0.5% 0;
}

.list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.skill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    font-size: 65px;
    padding: 0.15em;
    border: 1.5px solid rgb(137, 169, 197);
    border-radius: 50%;
    transition: transform 1.5s ease-in-out;
}

.skill-description {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.7em;
    overflow: hidden;
    white-space: nowrap;
}

.skill-header {
    margin: 0;
}

.fa-calendar-alt {
    font-size: 30px;
    width: 32px;
    height: 32px;
    margin-top: 5px;
}

.skill-text {
    margin: 0;
    margin-top: 5px;
    padding: 0;
}

.break {
    flex-basis: 100%;
    height: 0;
}

@media (max-width: 1050px) {
  .text, .skills {
    padding: 0 10vw;
  }

  .header {
    margin-left: 0;
    font-size: 30px;
    text-align: center;
  }

  .skill-text {
      padding: 0;
  }

  .opening-text {
    top: 30%;
  }

  .text {
    font-size: 18px;
  }

  .skill {
    font-size: 40px;
    width: 40px;
    height: 40px;
  }

  .fa-calendar-alt {
    font-size: 25px;
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 900px) {
    .skills li {
        width: 100%;
    }
}

@media (max-height: 800px) {
  .opening-text {
    top: 20%;
  }
}

@media (max-width: 500px) {
    .page-title {
        font-size: 75px;
    }

    .fa-calendar-alt {
        font-size: 20px;
        width: 25px;
        height: 25px;
    }

    .skill-header {
        font-size: 20px;
    }

    .skill-text {
        font-size: 14px;
    }
}